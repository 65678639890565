
.parentModelToolBar {
    display: flex;
    height: 100%;
}
  
.div1ModelToolBar {
}

.div2ModelToolBar {
}

.div3ModelToolBar {
}
  
input[type="double"] {
    overflow: visible;
    width: 55px;
    text-align: left;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 13%, 95%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 28px;
    padding-left: 3px;
    margin-left: 3px;
}
  
input[type="double"]:read-only{
    background-color: hsla(231, 5%, 74%, 0.18);
    border-color: hsla(229, 4%, 64%, 0.58);
}

.parentToolsSection {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
  
.div1ToolsSection {
    grid-area: 1 / 1 / 2 / 2;
    padding: 1%;
}

.div2ToolsSection {
    grid-area: 1 / 2 / 2 / 3;
    padding: 1%;
}

.parentDropDown {
    display: flex;
    gap: 3px;
}
    
.div1DropDown label > span > span { 
    margin-left: 3px;
}
.div2DropDown {min-width: 75px;
    flex-grow: 1; }


.div1parentModelToolBar{
    padding: 1% 3% 1% 2%;
    min-width: 230px;
    border-right: 1px solid #80808038;
    
}
.div2parentModelToolBar{
    padding: 1% 3% 1% 2%;
    border-right: 1px solid #80808038;
}
.div3parentModelToolBar{
    flex-grow: 1;
    padding: 1% 3% 1% 2%;
}

input.inputArrow{
    width:45px;
}



@media (max-width: 1300px) {
    
    .div1parentModelToolBar{
        padding: 1%;
        
        box-shadow: rgba(129, 133, 136, 0.45) 0px 0px 1px inset;
    }

    .div2parentModelToolBar{
        padding: 1% ;
        box-shadow: rgba(129, 133, 136, 0.45) 0px 0px 1px inset;
    }

    .div3parentModelToolBar{
        padding: 1% ;
        box-shadow: rgba(129, 133, 136, 0.45) 0px 0px 1px inset;
    }

    input[type="double"] {
      width: 30px;
    }

    .inputArrow{
        width:35px;
    }

    .div1DropDown { min-width: 90px}
    .div2DropDown { min-width: 90px}

}



@media (max-width: 800px) {
    .parentModelToolBar {
        display: block;
    }

    .div1parentModelToolBar{
        padding: 0 2%;
    }

    .div2parentModelToolBar{
        padding: 0 2%;
    }

    .div3parentModelToolBar{
        padding: 0 2%;
    }

    input[type="double"] {
      width: 50px;
    }

    .inputArrow{
        width:35px;
    }

}


@media (max-width: 500px) {
    .parentModelToolBar {
        display: block;
    }

    .div1parentModelToolBar{
        padding: 0 2%;
    }

    .div2parentModelToolBar{
        padding: 0 2%;
    }

    .div3parentModelToolBar{
        padding: 0 2%;
    }

    input[type="double"] {
      width: 30px;
    }

    .inputArrow{
        width:30px;
    }

}
  