.bendingMomentChart{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 20px 0;
}


@media (max-width: 800px) {
    .bendingMomentChart{
        position: static;
        transform: none;
    }

}