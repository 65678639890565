.languageSelector{
    float: right;
    width: 170px;
    padding: 10px;
}

@media (max-width: 400px) {
    .languageSelector{
        float: none;
        width: 100%;
        margin:0 auto;
        padding:10px 5px;
    }
}