
.parent {
  display: grid;
  grid-template-columns: repeat(17, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  background-color: #b3a0a0a8;
  height: 100vh;
}

.parent > div{
  background-color: rgb(234, 227, 227);
}

.parent > .explorerPanel {
  grid-area: 2 / 1 / 11 / 3;
  overflow-y: auto;
  overflow-x: hidden;
}

.parent > .toolsPanel {
  grid-area: 2 / 15 / 11 / 18;
  padding: 1%;
  overflow-y: auto;
}

.parent > .menuToolsPanel {
  grid-area: 1 / 1 / 2 / 18;
}

.parent >.modelToolBarPanel {
  grid-area: 2 / 3 / 3 / 15;
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
}

.parent >.beamPanel {
  grid-area: 3 / 3 / 7 / 15;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
}

.parent > .bendingMomentPanel {
  grid-area: 7 / 3 / 9 / 10;
  overflow-y: auto;
  background-color: white;
}

.parent > .bendingMomentPanel > .momentLabelWrapper {
  display: flex;
  align-items: center;
  padding-top:1px;
  padding-bottom:1px;

  background-color: rgb(234, 227, 227);
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
}

.parent > .bendingMomentPanel > label {
  padding-left: 1%;
}

.parent > .deflectionPanel > label {
  padding-left: 1%;
}

.parent > .deflectionPanel {
  grid-area: 9 / 3 / 13 / 10;
  overflow-y: auto;
  background-color: white;
}

.parent > .deflectionPanel > .deflectionLabelWrapper {
  display: flex;
  align-items: center;
  padding-top:1px;
  padding-bottom:1px;

  background-color: rgb(234, 227, 227);
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
}

.parent > .profilePanel {
  grid-area: 7 / 10 / 9 / 15;
  padding: 1%;
}

.parent > .profilePanel > .specialDiv{
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
  height: 100%;
}

.parent > .panelSummaryPanel {
  grid-area: 9 / 10 / 11 / 15;
  overflow-y: auto;
}

.modalInformationWrapper{
  display: flex;
  flex-direction: column;
  max-width:500px;
  width:100%;
  max-height:100vh ;
}

.modalInformationWrapper >.programInformation {
  margin-bottom: 20px;
  background: #337AB7;
  color: white;
  padding: 7px 11px;
  font-size: 14px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.modalInformationWrapper > .fieldsWrapper{
  display: flex;
  flex-direction: column;
  padding: 0px 7px;
}

.modalInformationWrapper > .fieldsWrapper > .elementField{
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
  width: 80%;
}

.modalInformationWrapper > .fieldsWrapper > .buttonsWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalInformationWrapper > .fieldsWrapper > .buttonsWrapper > button{
  background: #337AB7;
  padding: 3px 23px;
  border: none;
  font-weight: 400;
  border-radius: 4px;
  color: #ffffff;
  min-width: 65px;
  margin-top: 15px;
  box-shadow: 1px 1px 1px #036198;
}

.modalInformationWrapper .MuiSvgIcon-root{
  font-size: 1.3rem !important;
}
.modalInformationWrapper > .fieldsWrapper > .buttonsWrapper > button >span{
  display: block;
}



.modalInformationWrapper > .fieldsWrapper > .elementField > input, textarea{
  border: 1px solid rgba(0, 0, 0, 0.28);
  min-height: 25px;
  padding:5px;
}

/* @media (max-height: 800px) {
  .parent {
    height: auto;
   max-height: 130vh;
  }
}

@media (max-height: 700px) {
  .parent {
    height: auto;
   max-height: 150vh;
  }
}

@media (max-height: 600px) {
  .parent {
    height: auto;
   max-height: 160vh;
  }
}

@media (max-height: 500px) {
  .parent {
    height: auto;
   max-height: 200vh;
  }
}

@media (max-height: 400px) {
  .parent {
    height: auto;
   max-height: 300vh;
  }
} */
.bendingMomentValueModeButton{
  font-weight: 600;
  border-radius: 4px;
  height: 18px;
  line-height: 18px;
  margin-left: 5px;
  margin-right: 5px;
  width:25px;
  transition: 0.2s;
  font-size:12px;
}

.bendingMomentValueModeInput{
  display: inline-block;
  min-width: 50px !important;
  min-height: 10px !important;
  height: 18px !important;
  line-height: 18px !important;
}

@media (max-width: 800px) {
  .parent {
   
    display:flex;
    flex-direction: column;

     height: auto;
     max-height: none;
  }
  .parent > div{
    /* padding: 25px 0px */
  }
  .parent > .explorerPanel {
    grid-area: 2 / 1 / 11 / 3;
    overflow-y: auto;
    overflow-x: hidden;
    order:9;
  
  }
  
  .parent > .toolsPanel {
   order: 4;
   max-height: 150px;
  
  }
  
  .parent > .menuToolsPanel {
    order:1;
   
  }
  
  .parent >.modelToolBarPanel {
   order:2;
  
  }
  
  .parent >.beamPanel {
    order:3;
  }

  
  
  .parent > .bendingMomentPanel {
    order: 5;
    /* height: 160px; */
    padding: 0px;
  }
  

  .parent > .deflectionPanel {
    order: 6;
    /* height: 160px; */
    padding: 0px;
  }
  
  .parent > .profilePanel {
   
    order: 7;
    
  }

  .parent > .profilePanel > .specialDiv {
   max-height: 200px;
    overflow: scroll;
  }
  
  .parent > .panelSummaryPanel {
    order: 8;
    
  }





}