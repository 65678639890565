.toolsWrapper{
    display: flex;
    flex-direction: column;
    height:100%;
    justify-content: space-between;
    padding-bottom: 10px; 
}

.buttonsWrapper{
    display: flex; 
    flex-direction: column;
}
.toolSection{
    padding: 10px;
}

.toolSection > label{
    font-weight: 700;
    font-family: inherit;
    margin: 0;
}

.geometrySection, .loadSection, .supportSection, .schemaSection{
    display: flex;
    flex-wrap: wrap;
}

.geometryButton{
    flex-grow: 1;
    margin: 3px;
    width: 90%;
}

.loadButton{
    flex-grow: 1;
    margin: 3px;
    width: 45%;
}

.supportButton{
    flex-grow: 1;
    margin: 3px;
    width: 45%;
}

.schemaButton{
    flex-grow: 1;
    margin: 3px;
    width: 45%;
}

.mefaInfoSectionWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    margin: 5px; 
}

.mefaInfoSection{
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.imgContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imgContainer > img{
    width: 100%;
    max-width: 170px;
}

.companyInfo{
    display: flex;
    flex-direction: column;
    max-width: 170px;
}

.companyInfo .cityName,.websiteName,.streetName{
    display: block;
    font-size: 12px;
    line-height: 1.5;
}

.companyInfo .companyName{
    display: block;
    font-size: 12px;
    line-height: 1.5;
    font-weight: bolder;
}

.MuiButton-label {
    width: 100%;
    display: block !important;
}

/* .modalWarning {
    padding: 25px 25px 10px 25px;
}

.modalWarning > form > button{
    background: #e04751;;
    padding: 5px 25px;
    border: none;
    font-weight: 400;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    margin-top: 25px;
}

.modalWarning > form > label{
    display: block;
    color: black;
    font-weight: 200;
    background-color: white;
    font-size: 18px;
} */

/* .modalWarning > form{
   text-align: center;
} */


.styles_modal__gNwvD {
    background-color: white;
    border-radius: 4px;
    width: 100%;
    max-width: 500px;
  }

.styles_closeButton__20ID4 {
    display: none;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-sizeSmall{
    font-family: inherit;
    text-transform: uppercase;
    font-weight: 500;
    line-height: initial;
}


@media (max-width: 1450px) {
    .toolSection{
        padding: 2px 10px;
    }

    .geometryButton{
        margin: 2px;
        width: 47%;
    }

    .loadButton, .supportButton, .schemaButton {
        margin: 2px;
        width: 47%;
    }

    button > span.MuiButton-label > label{
        display: none !important;
    }

    .companyInfo .cityName,.websiteName,.streetName{
        font-size: 11px;
        line-height: 1.3;
    }
    
    .companyInfo .companyName{
        font-size: 11px;
        line-height: 1.3;
    }
}

@media (max-width: 1300px) {
    .companyInfo .cityName,.websiteName,.streetName{
        font-size: 10px;
        line-height: 1.3;
    }
    
    .companyInfo .companyName{
        font-size: 10px;
        line-height: 1.3;
    }
}
