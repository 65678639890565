.elementsTreeViewButtonCancel{
  position: absolute; 
  bottom: 0;
  right: 0;
  margin: 15px;
  background: #337AB7;
  padding: 3px 23px;
  border: none;
  font-weight: 400;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
}

.elementsTreeViewLabelInformationName{
  width: calc(100% + 2px);
  margin-bottom: 17px;
  background: #337AB7;
  color: white;
  padding: 8px 11px;
  font-size: 12px;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.my-modal-custom-class{
width: 500px !important;
height: 400px !important;
box-shadow: #a5a0a038 1px 2px 4px 1px;
}

.flexible-modal-resizer {
position: unset !important;
right: 0;
bottom: 0;
cursor: se-resize;
margin: 5px;
width: 0px !important;
height: 0px !important

}
.flexible-modal-drag-area {
background:transparent;
height: 30px;
position: absolute;
right: 0;
top: 0;
cursor: move;
width: 100% !important;
}

.flexible-modal {
position: fixed !important;
z-index: 1;
background: white;
}

.flexible-modal:hover {
position: fixed !important;
z-index: 9999999;
background: white;
}

.treeViewTable > .table{
  width: 85% !important;
  margin: 5px 35px;
  text-align: inherit;
  font-size: 12px;
}

.treeViewTable > .table td, .table th {
  padding: 0.154rem !important;
}


@media (max-width: 640px) and (orientation: portrait) {
.treeViewTable > .table{
  width: 85% !important;
  margin: 5px 20px;
  text-align: inherit;
  font-size: 11px;
}

.my-modal-custom-class{
  width: calc(100vw - 20px) !important;
  height:calc(100vh - 20px) !important;
  margin:10px;
}
}


@media (max-height: 500px) and (orientation: landscape) {
.treeViewTable > .table{
  width: 85% !important;
  margin: 5px 20px;
  text-align: inherit;
  font-size: 11px;
  line-height: 1.2;
}
.my-modal-custom-class{
  width: 500px !important;
  height: calc(100vh - 10px) !important;
  min-height: 320px !important;
  margin:5px calc(50% - 250px);

}

}



