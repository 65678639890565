@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
*{
  margin:0;
  padding:0; 
  box-sizing: border-box; 
  border-radius: 4px;
  border:none;
}

body {
  font-family: "-apple-system,BlinkMacSystemFont","Segoe UI","Roboto","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}

label{
  font-size: 12px;
}

.MuiTypography-body1 {
  font-size: 13px !important;
}



.menu {
  z-index: 1;
  position: fixed;
  display: none;
  transition: 0.2s display ease-in;
  padding: 3px;
  border: 1px solid #00000030;
  background: #ffffffad;
  /* box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2); */
}

button,input,textarea{
    outline:none !important;
    border-color: whitesmoke;
}


@media screen and (max-width : 32200px)
  {
    body 
    {
      font-size: small !important;
    }

    .summaryPanelButton{
      margin-top:2%;
    }
  }

  @media screen and (max-width : 2200px)
{
  body 
  {
    font-size: small !important;
  }

  .summaryPanelButton{
    margin-top:2%;
  }
}
@media screen and (max-width : 1604px)
{
  body
  {
    font-size: smaller !important;
  }
 
  .summaryPanelButton{
    margin-top:1%;
  }
}


@-webkit-keyframes ldio-lfxk7vuevgr {
  0% { transform: rotate(0deg) }
 50% { transform: rotate(22.5deg) }
100% { transform: rotate(45deg) }
}


@keyframes ldio-lfxk7vuevgr {
  0% { transform: rotate(0deg) }
 50% { transform: rotate(22.5deg) }
100% { transform: rotate(45deg) }
}

.ldio-lfxk7vuevgr > div {
transform-origin: 100px 100px;
-webkit-animation: ldio-lfxk7vuevgr 0.3s infinite linear;
        animation: ldio-lfxk7vuevgr 0.3s infinite linear;
}

.ldio-lfxk7vuevgr > div div {
  position: absolute;
  width: 22px;
  height: 152px;
  background: #e15b64;
  left: 100px;
  top: 100px;
  transform: translate(-50%,-50%);
}

.ldio-lfxk7vuevgr > div div:nth-child(1) {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.ldio-lfxk7vuevgr > div div:nth-child(6) {
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50%;
}

.ldio-lfxk7vuevgr > div div:nth-child(3) {
transform: translate(-50%,-50%) rotate(45deg)}

.ldio-lfxk7vuevgr > div div:nth-child(4) {
transform: translate(-50%,-50%) rotate(90deg)}

.ldio-lfxk7vuevgr > div div:nth-child(5) {
transform: translate(-50%,-50%) rotate(135deg)}

.loadingio-spinner-gear-ayaf9ot4bp4 {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-lfxk7vuevgr {
width: 100%;
height: 100%;
position: relative;
transform: translateZ(0) scale(1);
-webkit-backface-visibility: hidden;
        backface-visibility: hidden;
transform-origin: 0 0; /* see note above */
}

.ldio-lfxk7vuevgr div { box-sizing: content-box; }

.loader-container{
    position:relative;
    height: 100vh;
    background-color: white;
}





 




.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}

.flexible-modal-mask {
  height: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}


.MuiAppBar-root {
  width: 100%;
  display: flex;
  z-index: 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
}





.table td, .table th {
   padding: 0.1rem !important;

  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}






  


  .MuiTab-labelIcon {
    min-height: auto !important;
    line-height: 33px !important;
  } 

  .menu-options {
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;
    display: grid;
  }
  
  .menu-option {
      z-index: 1;
      cursor: pointer;
  }
      
  .menu-option:hover {
      background: rgb(174, 202, 231);
  }

  .styles_overlay__CLSq- {
    background: rgb(0 0 0 / 39%) !important;
  }

  .summaryPanelButton > button.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-root.MuiButton-sizeSmall.MuiButtonBase-root {
    box-shadow: none;
}

.parent {
  display: grid;
  grid-template-columns: repeat(17, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  background-color: #b3a0a0a8;
  height: 100vh;
}

.parent > div{
  background-color: rgb(234, 227, 227);
}

.parent > .explorerPanel {
  grid-area: 2 / 1 / 11 / 3;
  overflow-y: auto;
  overflow-x: hidden;
}

.parent > .toolsPanel {
  grid-area: 2 / 15 / 11 / 18;
  padding: 1%;
  overflow-y: auto;
}

.parent > .menuToolsPanel {
  grid-area: 1 / 1 / 2 / 18;
}

.parent >.modelToolBarPanel {
  grid-area: 2 / 3 / 3 / 15;
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
}

.parent >.beamPanel {
  grid-area: 3 / 3 / 7 / 15;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
}

.parent > .bendingMomentPanel {
  grid-area: 7 / 3 / 9 / 10;
  overflow-y: auto;
  background-color: white;
}

.parent > .bendingMomentPanel > .momentLabelWrapper {
  display: flex;
  align-items: center;
  padding-top:1px;
  padding-bottom:1px;

  background-color: rgb(234, 227, 227);
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
}

.parent > .bendingMomentPanel > label {
  padding-left: 1%;
}

.parent > .deflectionPanel > label {
  padding-left: 1%;
}

.parent > .deflectionPanel {
  grid-area: 9 / 3 / 13 / 10;
  overflow-y: auto;
  background-color: white;
}

.parent > .deflectionPanel > .deflectionLabelWrapper {
  display: flex;
  align-items: center;
  padding-top:1px;
  padding-bottom:1px;

  background-color: rgb(234, 227, 227);
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
}

.parent > .profilePanel {
  grid-area: 7 / 10 / 9 / 15;
  padding: 1%;
}

.parent > .profilePanel > .specialDiv{
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: 0px 1px 3px 0px rgb(136,136,136);
  height: 100%;
}

.parent > .panelSummaryPanel {
  grid-area: 9 / 10 / 11 / 15;
  overflow-y: auto;
}

.modalInformationWrapper{
  display: flex;
  flex-direction: column;
  max-width:500px;
  width:100%;
  max-height:100vh ;
}

.modalInformationWrapper >.programInformation {
  margin-bottom: 20px;
  background: #337AB7;
  color: white;
  padding: 7px 11px;
  font-size: 14px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.modalInformationWrapper > .fieldsWrapper{
  display: flex;
  flex-direction: column;
  padding: 0px 7px;
}

.modalInformationWrapper > .fieldsWrapper > .elementField{
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
  width: 80%;
}

.modalInformationWrapper > .fieldsWrapper > .buttonsWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalInformationWrapper > .fieldsWrapper > .buttonsWrapper > button{
  background: #337AB7;
  padding: 3px 23px;
  border: none;
  font-weight: 400;
  border-radius: 4px;
  color: #ffffff;
  min-width: 65px;
  margin-top: 15px;
  box-shadow: 1px 1px 1px #036198;
}

.modalInformationWrapper .MuiSvgIcon-root{
  font-size: 1.3rem !important;
}
.modalInformationWrapper > .fieldsWrapper > .buttonsWrapper > button >span{
  display: block;
}



.modalInformationWrapper > .fieldsWrapper > .elementField > input, textarea{
  border: 1px solid rgba(0, 0, 0, 0.28);
  min-height: 25px;
  padding:5px;
}

/* @media (max-height: 800px) {
  .parent {
    height: auto;
   max-height: 130vh;
  }
}

@media (max-height: 700px) {
  .parent {
    height: auto;
   max-height: 150vh;
  }
}

@media (max-height: 600px) {
  .parent {
    height: auto;
   max-height: 160vh;
  }
}

@media (max-height: 500px) {
  .parent {
    height: auto;
   max-height: 200vh;
  }
}

@media (max-height: 400px) {
  .parent {
    height: auto;
   max-height: 300vh;
  }
} */
.bendingMomentValueModeButton{
  font-weight: 600;
  border-radius: 4px;
  height: 18px;
  line-height: 18px;
  margin-left: 5px;
  margin-right: 5px;
  width:25px;
  transition: 0.2s;
  font-size:12px;
}

.bendingMomentValueModeInput{
  display: inline-block;
  min-width: 50px !important;
  min-height: 10px !important;
  height: 18px !important;
  line-height: 18px !important;
}

@media (max-width: 800px) {
  .parent {
   
    display:flex;
    flex-direction: column;

     height: auto;
     max-height: none;
  }
  .parent > div{
    /* padding: 25px 0px */
  }
  .parent > .explorerPanel {
    grid-area: 2 / 1 / 11 / 3;
    overflow-y: auto;
    overflow-x: hidden;
    order:9;
  
  }
  
  .parent > .toolsPanel {
   order: 4;
   max-height: 150px;
  
  }
  
  .parent > .menuToolsPanel {
    order:1;
   
  }
  
  .parent >.modelToolBarPanel {
   order:2;
  
  }
  
  .parent >.beamPanel {
    order:3;
  }

  
  
  .parent > .bendingMomentPanel {
    order: 5;
    /* height: 160px; */
    padding: 0px;
  }
  

  .parent > .deflectionPanel {
    order: 6;
    /* height: 160px; */
    padding: 0px;
  }
  
  .parent > .profilePanel {
   
    order: 7;
    
  }

  .parent > .profilePanel > .specialDiv {
   max-height: 200px;
    overflow: scroll;
  }
  
  .parent > .panelSummaryPanel {
    order: 8;
    
  }





}
.elementsTreeViewButtonCancel{
  position: absolute; 
  bottom: 0;
  right: 0;
  margin: 15px;
  background: #337AB7;
  padding: 3px 23px;
  border: none;
  font-weight: 400;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
}

.elementsTreeViewLabelInformationName{
  width: calc(100% + 2px);
  margin-bottom: 17px;
  background: #337AB7;
  color: white;
  padding: 8px 11px;
  font-size: 12px;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.my-modal-custom-class{
width: 500px !important;
height: 400px !important;
box-shadow: #a5a0a038 1px 2px 4px 1px;
}

.flexible-modal-resizer {
position: unset !important;
right: 0;
bottom: 0;
cursor: se-resize;
margin: 5px;
width: 0px !important;
height: 0px !important

}
.flexible-modal-drag-area {
background:transparent;
height: 30px;
position: absolute;
right: 0;
top: 0;
cursor: move;
width: 100% !important;
}

.flexible-modal {
position: fixed !important;
z-index: 1;
background: white;
}

.flexible-modal:hover {
position: fixed !important;
z-index: 9999999;
background: white;
}

.treeViewTable > .table{
  width: 85% !important;
  margin: 5px 35px;
  text-align: inherit;
  font-size: 12px;
}

.treeViewTable > .table td, .table th {
  padding: 0.154rem !important;
}


@media (max-width: 640px) and (orientation: portrait) {
.treeViewTable > .table{
  width: 85% !important;
  margin: 5px 20px;
  text-align: inherit;
  font-size: 11px;
}

.my-modal-custom-class{
  width: calc(100vw - 20px) !important;
  height:calc(100vh - 20px) !important;
  margin:10px;
}
}


@media (max-height: 500px) and (orientation: landscape) {
.treeViewTable > .table{
  width: 85% !important;
  margin: 5px 20px;
  text-align: inherit;
  font-size: 11px;
  line-height: 1.2;
}
.my-modal-custom-class{
  width: 500px !important;
  height: calc(100vh - 10px) !important;
  min-height: 320px !important;
  margin:5px calc(50% - 250px);

}

}




.MuiTreeItem-group {
    margin: 0;
    padding: 0;
    margin-left: 16px !important;
}
.table td, .table th {
    vertical-align: middle !important;
}

.parentModelToolBar {
    display: flex;
    height: 100%;
}
  
.div1ModelToolBar {
}

.div2ModelToolBar {
}

.div3ModelToolBar {
}
  
input[type="double"] {
    overflow: visible;
    width: 55px;
    text-align: left;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 13%, 95%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 28px;
    padding-left: 3px;
    margin-left: 3px;
}
  
input[type="double"]:-moz-read-only{
    background-color: hsla(231, 5%, 74%, 0.18);
    border-color: hsla(229, 4%, 64%, 0.58);
}
  
input[type="double"]:read-only{
    background-color: hsla(231, 5%, 74%, 0.18);
    border-color: hsla(229, 4%, 64%, 0.58);
}

.parentToolsSection {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
  
.div1ToolsSection {
    grid-area: 1 / 1 / 2 / 2;
    padding: 1%;
}

.div2ToolsSection {
    grid-area: 1 / 2 / 2 / 3;
    padding: 1%;
}

.parentDropDown {
    display: flex;
    grid-gap: 3px;
    gap: 3px;
}
    
.div1DropDown label > span > span { 
    margin-left: 3px;
}
.div2DropDown {min-width: 75px;
    flex-grow: 1; }


.div1parentModelToolBar{
    padding: 1% 3% 1% 2%;
    min-width: 230px;
    border-right: 1px solid #80808038;
    
}
.div2parentModelToolBar{
    padding: 1% 3% 1% 2%;
    border-right: 1px solid #80808038;
}
.div3parentModelToolBar{
    flex-grow: 1;
    padding: 1% 3% 1% 2%;
}

input.inputArrow{
    width:45px;
}



@media (max-width: 1300px) {
    
    .div1parentModelToolBar{
        padding: 1%;
        
        box-shadow: rgba(129, 133, 136, 0.45) 0px 0px 1px inset;
    }

    .div2parentModelToolBar{
        padding: 1% ;
        box-shadow: rgba(129, 133, 136, 0.45) 0px 0px 1px inset;
    }

    .div3parentModelToolBar{
        padding: 1% ;
        box-shadow: rgba(129, 133, 136, 0.45) 0px 0px 1px inset;
    }

    input[type="double"] {
      width: 30px;
    }

    .inputArrow{
        width:35px;
    }

    .div1DropDown { min-width: 90px}
    .div2DropDown { min-width: 90px}

}



@media (max-width: 800px) {
    .parentModelToolBar {
        display: block;
    }

    .div1parentModelToolBar{
        padding: 0 2%;
    }

    .div2parentModelToolBar{
        padding: 0 2%;
    }

    .div3parentModelToolBar{
        padding: 0 2%;
    }

    input[type="double"] {
      width: 50px;
    }

    .inputArrow{
        width:35px;
    }

}


@media (max-width: 500px) {
    .parentModelToolBar {
        display: block;
    }

    .div1parentModelToolBar{
        padding: 0 2%;
    }

    .div2parentModelToolBar{
        padding: 0 2%;
    }

    .div3parentModelToolBar{
        padding: 0 2%;
    }

    input[type="double"] {
      width: 30px;
    }

    .inputArrow{
        width:30px;
    }

}
  
.toolsWrapper{
    display: flex;
    flex-direction: column;
    height:100%;
    justify-content: space-between;
    padding-bottom: 10px; 
}

.buttonsWrapper{
    display: flex; 
    flex-direction: column;
}
.toolSection{
    padding: 10px;
}

.toolSection > label{
    font-weight: 700;
    font-family: inherit;
    margin: 0;
}

.geometrySection, .loadSection, .supportSection, .schemaSection{
    display: flex;
    flex-wrap: wrap;
}

.geometryButton{
    flex-grow: 1;
    margin: 3px;
    width: 90%;
}

.loadButton{
    flex-grow: 1;
    margin: 3px;
    width: 45%;
}

.supportButton{
    flex-grow: 1;
    margin: 3px;
    width: 45%;
}

.schemaButton{
    flex-grow: 1;
    margin: 3px;
    width: 45%;
}

.mefaInfoSectionWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    margin: 5px; 
}

.mefaInfoSection{
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.imgContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imgContainer > img{
    width: 100%;
    max-width: 170px;
}

.companyInfo{
    display: flex;
    flex-direction: column;
    max-width: 170px;
}

.companyInfo .cityName,.websiteName,.streetName{
    display: block;
    font-size: 12px;
    line-height: 1.5;
}

.companyInfo .companyName{
    display: block;
    font-size: 12px;
    line-height: 1.5;
    font-weight: bolder;
}

.MuiButton-label {
    width: 100%;
    display: block !important;
}

/* .modalWarning {
    padding: 25px 25px 10px 25px;
}

.modalWarning > form > button{
    background: #e04751;;
    padding: 5px 25px;
    border: none;
    font-weight: 400;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    margin-top: 25px;
}

.modalWarning > form > label{
    display: block;
    color: black;
    font-weight: 200;
    background-color: white;
    font-size: 18px;
} */

/* .modalWarning > form{
   text-align: center;
} */


.styles_modal__gNwvD {
    background-color: white;
    border-radius: 4px;
    width: 100%;
    max-width: 500px;
  }

.styles_closeButton__20ID4 {
    display: none;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-sizeSmall{
    font-family: inherit;
    text-transform: uppercase;
    font-weight: 500;
    line-height: normal;
    line-height: initial;
}


@media (max-width: 1450px) {
    .toolSection{
        padding: 2px 10px;
    }

    .geometryButton{
        margin: 2px;
        width: 47%;
    }

    .loadButton, .supportButton, .schemaButton {
        margin: 2px;
        width: 47%;
    }

    button > span.MuiButton-label > label{
        display: none !important;
    }

    .companyInfo .cityName,.websiteName,.streetName{
        font-size: 11px;
        line-height: 1.3;
    }
    
    .companyInfo .companyName{
        font-size: 11px;
        line-height: 1.3;
    }
}

@media (max-width: 1300px) {
    .companyInfo .cityName,.websiteName,.streetName{
        font-size: 10px;
        line-height: 1.3;
    }
    
    .companyInfo .companyName{
        font-size: 10px;
        line-height: 1.3;
    }
}

.languageSelector{
    float: right;
    width: 170px;
    padding: 10px;
}

@media (max-width: 400px) {
    .languageSelector{
        float: none;
        width: 100%;
        margin:0 auto;
        padding:10px 5px;
    }
}
.modelTabWrapper .MuiTab-textColorPrimary.Mui-selected {
    color: #337ab7 !important;
    max-height: 40px;
}

.modelTabWrapper .PrivateTabIndicator-colorPrimary-113 {
    background-color: #337ab7 !important;
}

.modelTabWrapper .MuiTabs-root {
    min-height: auto !important;
    max-height: 40px;
}

.modelTabWrapper .MuiTab-textColorPrimary {
    max-height: 40px;
}

.modelTabWrapper .PrivateTabIndicator-colorPrimary-71 {
    background-color: #337ab7 !important;
}

.modelTabWrapper .MuiTab-wrapper {
    display: flex;
    flex-direction: row-reverse;
    font-size: 11px;
    overflow: hidden;
    margin-right: 5px;
}

.modelTabWrapper .MuiSvgIcon-root{
    font-size: 1.2rem !important;
}

.modelTabWrapper .MuiTab-root {
    font-family: inherit !important;
    max-width: 140px !important;
    width: 140px;
    min-width: 100px !important; 
    padding: 6px 20px !important;
}

.modelTabWrapper .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    position: absolute;
    top: 5px;
    right: 5px;
}

.BeamClass{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}


@media (max-width: 800px) {
    .BeamClass{
        position: static;
        transform: none;
    }

}
.bendingMomentChart{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 20px 0;
}


@media (max-width: 800px) {
    .bendingMomentChart{
        position: static;
        transform: none;
    }

}
.deflectionChart{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 20px 0;
}


@media (max-width: 800px) {
    .deflectionChart{
        position: static;
        transform: none;
    }

}
