@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

*{
  margin:0;
  padding:0; 
  box-sizing: border-box; 
  border-radius: 4px;
  border:none;
}

body {
  font-family: "-apple-system,BlinkMacSystemFont","Segoe UI","Roboto","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}

label{
  font-size: 12px;
}

.MuiTypography-body1 {
  font-size: 13px !important;
}



.menu {
  z-index: 1;
  position: fixed;
  display: none;
  transition: 0.2s display ease-in;
  padding: 3px;
  border: 1px solid #00000030;
  background: #ffffffad;
  /* box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2); */
}

button,input,textarea{
    outline:none !important;
    border-color: whitesmoke;
}


@media screen and (max-width : 32200px)
  {
    body 
    {
      font-size: small !important;
    }

    .summaryPanelButton{
      margin-top:2%;
    }
  }

  @media screen and (max-width : 2200px)
{
  body 
  {
    font-size: small !important;
  }

  .summaryPanelButton{
    margin-top:2%;
  }
}
@media screen and (max-width : 1604px)
{
  body
  {
    font-size: smaller !important;
  }
 
  .summaryPanelButton{
    margin-top:1%;
  }
}


@keyframes ldio-lfxk7vuevgr {
  0% { transform: rotate(0deg) }
 50% { transform: rotate(22.5deg) }
100% { transform: rotate(45deg) }
}

.ldio-lfxk7vuevgr > div {
transform-origin: 100px 100px;
animation: ldio-lfxk7vuevgr 0.3s infinite linear;
}

.ldio-lfxk7vuevgr > div div {
  position: absolute;
  width: 22px;
  height: 152px;
  background: #e15b64;
  left: 100px;
  top: 100px;
  transform: translate(-50%,-50%);
}

.ldio-lfxk7vuevgr > div div:nth-child(1) {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.ldio-lfxk7vuevgr > div div:nth-child(6) {
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50%;
}

.ldio-lfxk7vuevgr > div div:nth-child(3) {
transform: translate(-50%,-50%) rotate(45deg)}

.ldio-lfxk7vuevgr > div div:nth-child(4) {
transform: translate(-50%,-50%) rotate(90deg)}

.ldio-lfxk7vuevgr > div div:nth-child(5) {
transform: translate(-50%,-50%) rotate(135deg)}

.loadingio-spinner-gear-ayaf9ot4bp4 {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-lfxk7vuevgr {
width: 100%;
height: 100%;
position: relative;
transform: translateZ(0) scale(1);
backface-visibility: hidden;
transform-origin: 0 0; /* see note above */
}

.ldio-lfxk7vuevgr div { box-sizing: content-box; }

.loader-container{
    position:relative;
    height: 100vh;
    background-color: white;
}





 




.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}

.flexible-modal-mask {
  height: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}


.MuiAppBar-root {
  width: 100%;
  display: flex;
  z-index: 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
}





.table td, .table th {
   padding: 0.1rem !important;

  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}






  


  .MuiTab-labelIcon {
    min-height: auto !important;
    line-height: 33px !important;
  } 

  .menu-options {
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;
    display: grid;
  }
  
  .menu-option {
      z-index: 1;
      cursor: pointer;
  }
      
  .menu-option:hover {
      background: rgb(174, 202, 231);
  }

  .styles_overlay__CLSq- {
    background: rgb(0 0 0 / 39%) !important;
  }

  .summaryPanelButton > button.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-root.MuiButton-sizeSmall.MuiButtonBase-root {
    box-shadow: none;
}