.modelTabWrapper .MuiTab-textColorPrimary.Mui-selected {
    color: #337ab7 !important;
    max-height: 40px;
}

.modelTabWrapper .PrivateTabIndicator-colorPrimary-113 {
    background-color: #337ab7 !important;
}

.modelTabWrapper .MuiTabs-root {
    min-height: auto !important;
    max-height: 40px;
}

.modelTabWrapper .MuiTab-textColorPrimary {
    max-height: 40px;
}

.modelTabWrapper .PrivateTabIndicator-colorPrimary-71 {
    background-color: #337ab7 !important;
}

.modelTabWrapper .MuiTab-wrapper {
    display: flex;
    flex-direction: row-reverse;
    font-size: 11px;
    overflow: hidden;
    margin-right: 5px;
}

.modelTabWrapper .MuiSvgIcon-root{
    font-size: 1.2rem !important;
}

.modelTabWrapper .MuiTab-root {
    font-family: inherit !important;
    max-width: 140px !important;
    width: 140px;
    min-width: 100px !important; 
    padding: 6px 20px !important;
}

.modelTabWrapper .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    position: absolute;
    top: 5px;
    right: 5px;
}
